import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { AzureMP } from "react-azure-mp"
import CookieConsent from "react-cookie-consent"

import "../assets/stylesheet.css"

import Background from "../assets/Background.png"
import LogoImage from "../assets/Logo.png"

const PhoneQuery = "@media only screen and (max-device-width: 960px)"

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        color: #fff;
        font-size: 16px;
    }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background: #1a1a1a;
`

const Wrapper = styled.div`
  width: 1024px;

  ${PhoneQuery} {
    width: 100%;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`

const BackgroundImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  z-index: 0;
`

const Logo = styled.img`
  z-index: 2;
  width: 392px;

  ${PhoneQuery} {
    width: 100%;
  }
`

const Video = styled.iframe`
  width: 100%;
  height: 576px;
  padding: 3rem 0 0 0;
  z-index: 2;
  border: none;
`

const EpisodeTitle = styled.div`
  padding-top: 24px;
  display: flex;
  width: 100%;

  ${PhoneQuery} {
    flex-wrap: wrap;
  }
`

const Text = styled.div`
  padding-left: 48px;

  ${PhoneQuery} {
    padding-left: 0;
  }

  color: #fefe66;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
`

const Number = styled.span`
  padding-left: 1px;
  color: transparent;
  -webkit-text-stroke: 1px #fefe66;
  letter-spacing: -3.5px;
`

const AndFriends = styled.h1`
  font-family: "FORCED SQUARE";
  font-size: 90.5px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  font-style: normal;

  ${PhoneQuery} {
    padding-top: 12px;
    font-size: 58px;
  }
`

const Featuring = styled.h2`
  margin: 0;
  font-family: "FORCED SQUARE";
  font-weight: 500;
  font-style: normal;
  font-size: 35.7px;

  ${PhoneQuery} {
    font-size: 22.8px;
  }
`

export const ImprintLink = styled.a``

export const Layout = props => {
  return (
    <Container>
      <GlobalStyle />
      <BackgroundImage src={Background} />
      <Wrapper>
        <AzureMP
          skin="amp-flush"
          src={[
            {
              src:
                "https://fdtdlivemedia-euwe.streaming.media.azure.net/09a5a57b-d57f-4b28-abf3-23cdb45902b7/bd2258b3-f231-4318-ba30-1952d1d98896.ism/manifest",
              type: "application/vnd.ms-sstr+xml",
            },
          ]}
        />
        <EpisodeTitle>
          <Logo src={LogoImage} />
          <Text>
            <AndFriends>
              & Friends<Number>003</Number>
            </AndFriends>
            <Featuring>with Sandro Marques & Rainer Zufall</Featuring>
          </Text>
        </EpisodeTitle>
        <CookieConsent
          location="bottom"
          buttonText="Okay"
          cookieName="myAwesomeCookieName2"
          style={{
            color: "#fefe66",
            background: "#282530",
            fontFamily: "Helvetica",
          }}
          buttonStyle={{
            color: "#fefe66",
            fontSize: "13px",
            background: "#ac8ae5",
          }}
          expires={150}
        >
          This website is using cookies to provide the best user experience
        </CookieConsent>
      </Wrapper>
    </Container>
  )
}
